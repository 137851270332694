import Intro from './components/Intro';
import AboutUs from './components/AboutUs';
import Header from './components/Header';

export default class App {
  pageMap;

  constructor() {
    new Intro();
    new AboutUs();
    new Header();
    this.setElement();
    this.addEvent();
  }

  setElement = () => {
    this.pageMap = {
      docs: 'https://docs.astrone.io',
      chromeStore:
        'https://chrome.google.com/webstore/detail/astrone-wallet/dpealdfjgahljjdekeiadjnfgpijffhg',
      finance: 'https://finance.astrone.io',
    };
  };

  addEvent = () => {
    window.addEventListener('click', this.handleClick);
  };

  handleClick = (event) => {
    if (!event.target.classList.contains('btn-link')) {
      return;
    }
    const { path } = event.target.dataset;
    this.openPage(this.pageMap[path]);
  };

  openPage = (url) => {
    window.open(url, '_blank');
  };
}
