import astroneWalletHome from '../assets/img/astrone-wallet-home.png';
import astroneWalletTransaction from '../assets/img/astrone-wallet-transaction.png';
import astroneWallet from '../assets/img/astrone-wallet.png';

export default class AboutUs {
  timer;
  check1;
  check2;
  imgElement;

  constructor() {
    this.setElement();
    this.addEvent();
  }

  setElement = () => {
    this.imgElement = document.getElementById('about_img');
    this.check1 = document.querySelector('.about_img-check1');
    this.check2 = document.querySelector('.about_img-check2');
    this.handleResize();
  };

  addEvent = () => {
    window.addEventListener('resize', this.handleResize);
  };

  handleResize = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (window.innerWidth < 769) {
      this.check1.style.backgroundColor = '#5833EB';
      this.check2.style.backgroundColor = 'gray';
      this.imgElement.src = astroneWalletHome;

      this.timer = setInterval(() => {
        const currentImage = '/' + this.imgElement.src.split('/').at(-1);
        if (currentImage !== astroneWalletHome) {
          this.check1.style.backgroundColor = '#5833EB';
          this.check2.style.backgroundColor = 'gray';
          return (this.imgElement.src = astroneWalletHome);
        }
        this.check2.style.backgroundColor = '#5833EB';
        this.check1.style.backgroundColor = 'gray';
        return (this.imgElement.src = astroneWalletTransaction);
      }, 2000);
    } else {
      this.imgElement.src = astroneWallet;
    }
  };
}
