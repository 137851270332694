import IconRightUpArrowBlack from '../assets/img/icon-right-up-arrow-black.svg';
import IconRightUpArrowPurple from '../assets/img/icon-right-up-arrow-purple.svg';

export default class Header {
  menu;
  menuBtn;

  constructor() {
    this.setElement();
    this.addEvent();
    this.setMenuImg();
  }

  setElement = () => {
    this.menu = document.querySelector('.header_item');
    this.menuBtn = document.querySelector('.header_menu');
    this.mobileMenuBtns = this.menu.querySelectorAll('a');
  };

  setMenuImg = () => {
    this.menu
      .querySelectorAll('.icon-right-up-arrow')
      .forEach((imgTag) => (imgTag.src = IconRightUpArrowBlack));
  };

  addEvent = () => {
    window.addEventListener('resize', this.handleDropDownMenuHide);
    this.menuBtn.addEventListener('click', this.handleDropDownMenuToggle);
    this.mobileMenuBtns.forEach((btn) => {
      btn.addEventListener('mouseover', this.handleMenuHover);
      btn.addEventListener('mouseout', this.handleMenuHover);
    });
  };

  handleDropDownMenuToggle = () => {
    if (this.menu.style.display === 'flex') {
      this.menu.style.display = 'none';
    } else {
      this.menu.style.display = 'flex';
    }
  };

  handleDropDownMenuHide = () => {
    this.menu.style.display = 'none';
  };

  handleMenuHover = (e) => {
    const img = e.currentTarget.querySelector('.icon-right-up-arrow');
    if (e.type === 'mouseover') {
      img.src = IconRightUpArrowPurple;
    } else {
      img.src = IconRightUpArrowBlack;
    }
  };
}
