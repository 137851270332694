export default class Intro {
  text;
  counter;
  currentLength;
  textElement;

  constructor() {
    this.setElement();
    this.animateTyping();
  }

  setElement = () => {
    this.text = 'verything';
    this.counter = 0;
    this.currentLength = 0;
    this.textElement = document.getElementById('everything');
  };

  animateTyping = async () => {
    if (this.currentLength === this.text.length) {
      this.currentLength = 0;
      this.textElement.textContent = 'E';
      await new Promise((res, rej) => {
        setTimeout(() => {
          res();
        }, 2000);
      });
    }
    this.textElement.textContent += this.text.charAt(this.currentLength);
    this.currentLength++;
    setTimeout(this.animateTyping, 500);
  };
}
